<template>
    <div id="user-center">
        <leftbar-component></leftbar-component>
        <div class="content">
            <div class="topbar">
                <topbar-component :items="items"></topbar-component>
            </div>
            <div class="statistics">
                <div class="editor">
                    <div class="top">
                        <h2>欢迎</h2>
                        <router-link to="/personalInfo">编辑资料</router-link>
                    </div>
                    <div class="user" v-if="userinfo">
                        <i v-if="!userinfo.headurl" class="icon-user"></i>
                        <img v-else :src="man.fast.getResourcePath(userinfo.headurl, 1)" class="avatar">
                        <div style="margin-left: 15px;">
                            <h2>{{ userinfo.realName }}</h2>
                            <p>{{ totalData.resume }}</p>
                        </div>
                    </div>
                </div>
                <div class="course-resources" v-if="roles === 'teacher'">
                    <div class="top">
                        <h2><i class="icon icon-download"></i>课件资源</h2>
                        <router-link to="/courseManagement">管理</router-link>
                    </div>
                    <p class="num">{{ totalData.coursewareCount }}</p>
                </div>
                <div class="amount" v-if="roles === 'teacher'">
                    <div class="top">
                        <h2><i class="icon icon-student"></i>学生总数</h2>
                        <router-link to="/studentManagement">管理</router-link>
                    </div>
                    <p class="num">{{ totalData.studentCount }}</p>
                </div>
            </div>
            <div class="order-list" v-if="roles === 'teacher'">
                <h2>
                    <span>课程订单</span>
                    <router-link to="/myOrders">更多</router-link>
                </h2>

                <div class="table">
                    <el-table
                            :data="orderList"
                            style="width: 100%">
                        <el-table-column
                                prop="orderId"
                                label="订单号">
                        </el-table-column>
                        <el-table-column
                                prop="price"
                                align="center"
                                width="100"
                                label="课件价格">
                        </el-table-column>
                        <el-table-column
                                prop="courseName"
                                align="center"
                                width="100"
                                label="所属课程">
                        </el-table-column>
                        <el-table-column
                                prop="buyId"
                                align="center"
                                width="100"
                                label="下单账号">
                        </el-table-column>
                        <el-table-column
                                prop="buyName"
                                align="center"
                                width="100"
                                label="下单姓名">
                        </el-table-column>
                        <el-table-column
                                prop="createTime"
                                align="center"
                                label="下单时间">
                            <template slot-scope="scope">
                                {{ scope.row.createTime | formatDate }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="status"
                                align="center"
                                width="120"
                                label="订单状态">
                            <template slot-scope="scope" class="table-status">
                            <span :style="{'color': scope.row.status === 1 ? '#1DC084' : scope.row.status === 2 ? '#DC000C' : '#666666'}"
                                  :class="scope.row.status === 2 ? 'off-shelf' : ''">{{ scope.row.status === 0 ? '审核中' : scope.row.status === 1 ? '上架中' : "已下架" }}</span>
                                <div class="off-shelf-reason" v-if="scope.row.status === 2">审核不通过</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <!--<v-data-table
                    :headers="orderHeaders"
                    :items="orderList"
                    :items-per-page="5"
                    hide-default-footer
                ></v-data-table>-->
                <listH5Component 
                    :list="orderList"
                >
                    <template v-slot:title="rowData" >
                        {{rowData.prop.name}}
                    </template>
                    <template v-slot:datetime="rowData" >
                        {{rowData.prop.orderTime | formatDateTime}}
                    </template>
                    <template v-slot:status="rowData" >
                        <span :style="{'color': rowData.prop.status === 1 ? '#1DC084' : rowData.prop.status === -2 ? '#DC000C' : '#666666','text-decoration':rowData.prop.status === -2 ? 'underline' : 'none'}" :class="rowData.prop.status === 2 ? 'off-shelf' : ''">
                            {{ rowData.prop.status === 0 ? '待审核' : rowData.prop.status === 1 ? '上架中' : "已下架" }}
                        </span>
                    </template>
                </listH5Component>
            </div>
            <div class="course-list" v-if="false">
                <h2>
                    <span>近期课程</span>
                    <router-link to="/myCourses">更多</router-link>
                </h2>
                <v-data-table
                    :headers="courseHeaders"
                    :items="courseList"
                    :items-per-page="5"
                    hide-default-footer
                ></v-data-table>
                <listH5Component 
                    :list="courseList"
                >
                    <template v-slot:title="rowData" >
                        {{rowData.prop.name}}
                    </template>
                    <template v-slot:datetime="rowData" >
                        {{rowData.prop.createTime | formatDateTime}}
                    </template>
                    <template v-slot:status="rowData" >
                        <span :style="{'color': rowData.prop.status === 1 ? '#1DC084' : rowData.prop.status === -2 ? '#DC000C' : '#666666','text-decoration':rowData.prop.status === -2 ? 'underline' : 'none'}" :class="rowData.prop.status === 2 ? 'off-shelf' : ''">
                            {{ rowData.prop.status === 0 ? '待审核' : rowData.prop.status === 1 ? '上架中' : "已下架" }}
                        </span>
                    </template>
                </listH5Component>
            </div>
        </div>
    </div>
</template>
<script>
    import LeftbarComponent from './component/leftbarComponent'
    import TopbarComponent from './component/topbarComponent'
    import listH5Component from './component/listH5Component'
    import {urls, banbaoUrl} from '@/lib/common'
    
    export default {
        name: "UserCenter",
        components: {
            LeftbarComponent,
            TopbarComponent,
            listH5Component
        },
        data() {
            return {
                pageSize: 10,
                pageCurrent: 1,
                items: [
                    {
                        text: '个人中心',
                        disabled: true,
                        href: '/user-center',
                    }
                ],
                orderHeaders: [
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: '课程名称',
                        sortable: false,
                        value: 'name'
                    },
                    {
                        text: '下单时间',
                        sortable: false,
                        value: 'createTime'
                    },
                    {
                        text: '订单状态',
                        sortable: false,
                        value: 'status'
                    }
                ],
                orderList: [],
                courseHeaders: [
                    {
                        text: 'ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: '课程名称', sortable: false, value: 'name' },
                    { text: '新增时间', sortable: false, value: 'createTime' },
                    { text: '订单状态', sortable: false, value: 'status' }
                ],
                courseList: [],
                userinfo: '',
                roles: '',
                totalData: {}
            };
        },
        methods: {
            getTotalData() {
                this.$http.get(urls.getPersonInfo + this.userinfo.userId).then(res => {
                    if (res.data.status === 200) {
                        this.totalData = res.data.data;
                        this.userinfo = {
                            ...this.userinfo,
                            headurl: res.data.data.headurl,
                        };
                        console.log(this.totalData)
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            courseFind() {
                this.$http.get(urls.courseFind + this.userinfo.userId).then(res => {
                    console.log(res)
                    if (res.data.status === 200) {

                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getCourseList() {
                const params = {
                    pageCurrent: this.pageCurrent,
                    pageSize: this.pageSize
                }
                this.$http.get(urls.coursewareList, params).then(res => {
                    if (res.data.status === 200) {
                        this.courseList = res.data.data.records;
                        this.$set(this.totalData, 'coursewareCount', res.data.data.total);
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getStudentList() {
                const params = {
                    pageCurrent: this.pageCurrent,
                    pageSize: this.pageSize
                }
                this.$http.get(urls.getStudentList, params).then(res => {
                    if (res.data.status === 200) {
                        this.studentList = res.data.data.records;
                        this.$set(this.totalData, 'studentCount', res.data.data.total);
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getOrderList() { // 获取订单列表
                const params = {
                    pageCurrent: this.pageCurrent,
                    pageSize: this.pageSize,
                    teacherId: this.userinfo.userId
                }
                this.$http.get(urls.getOrderList, {params}).then(res => {
                    // console.log(res)
                    if (res.data.status === 200) {
                        this.orderList = res.data.data.records;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        created() {
            this.roles = sessionStorage.getItem('roles') || this.$cookies.get('app.roles', {domain: banbaoUrl, path: ''});
            if (sessionStorage.getItem('userinfo')) {
                this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
            } else if (this.$cookies.get('app.uid', {domain: banbaoUrl, path: ''})) {
                this.userinfo = {
                    userId: this.$cookies.get('app.uid', {domain: banbaoUrl, path: ''})
                }
            }
            this.getTotalData();
            if (this.roles === 'teacher') {
                this.getOrderList();
                this.getCourseList();
                this.getStudentList();
                // setTimeout(() => {
                //     console.log(this.totalData)
                // }, 3000)
            }
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #user-center {
        background: #F2FCFF;
        display: flex;
        justify-content: flex-start;
        .content {
            width: 84%;
            box-sizing: border-box;
            background: #F2FCFF;
            padding-bottom: 5%;
            .statistics {
                display: flex;
                justify-content: space-between;
                width: 90%;
                >div {
                    background: #fff;
                    width: 30%;
                    min-height: 9.375vw;
                    padding: 1vw;
                    margin: 1vw;
                    border-radius: 10px;
                    box-sizing: border-box;
                    &:last-child {
                        margin-right: 0;
                    }
                    h2 {
                        font-size: 1.25vw;
                    }
                    .top {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        a {
                            color: #02A5E6;
                            font-size: 0.83vw;
                        }
                    }
                    .num {
                        text-align: center;
                        font-size: 3.75vw;
                        margin-top: 1.4vw;
                    }
                    .user {
                        display: flex;
                        justify-content: flex-start;
                        padding-top: 20px;
                        .icon-user {
                            flex: 1;
                            display: inline-block;
                            width: 3.125vw;
                            height: 3.125vw;
                            margin-right: 20px;
                            background: url('../../assets/images/user-center/icon_user.png') no-repeat center center;
                            background-size: contain;
                        }
                        .avatar {width: 50px; height: 50px; border-radius: 90px;}
                        >div {
                            flex: 3;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            >p {
                                color: #5F5F5F;
                                margin-top: 0.5vw;
                            }
                        }
                    }
                }

                .course-resources,
                .amount{
                    .top{
                        h2{
                            .icon{
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-size: 100% auto;
                                margin-right: 8px;
                                display: inline-block;
                                vertical-align: top;
                                position: relative;
                                top: 4px;
                            }
                            .icon-download{
                                background-image: url('../../assets/images/phone/icon_download.png');
                                width: 16px;
                                height: 20px;
                                
                            }
                            .icon-student{
                                background-image: url('../../assets/images/phone/icon_student.png');
                                width: 14px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
            .order-list,
            .course-list {
                width: 90%;
                box-sizing: border-box;
                padding-left: 1vw;
                margin-top: 1vw;
                >h2 {
                    font-size: 0.9375vw;
                    margin-bottom: 1vw;
                    display: flex;
                    justify-content: space-between;
                    a {
                        color: #999;
                        font-weight: normal;
                    }
                }
                @{deep} .v-data-table {
                    .v-data-table-header {
                        background: #24CEFF;
                        th {
                            color: #fff;
                            font-size: 0.83vw;
                            &:first-child {
                                border-radius: 6px 0 0 0;
                            }
                            &:last-child {
                                border-radius: 0 6px 0 0;
                            }
                        }
                    }
                    tbody {
                        td {
                            font-size: 0.83vw;
                        }
                    }
                }
            }

            .table {
                @{deep} .el-table {
                    overflow: visible;
                    &::before {
                        width: 0;
                        height: 0;
                    }
                    thead {
                        th {
                            background-color: #24CEFF;
                            color: #fff;
                        }
                        th:first-child {
                            border-radius: 10px 0 0 0;
                        }
                        th:nth-child(7) {
                            border-radius: 0 10px 0 0;
                        }
                    }
                    .el-table__body-wrapper {
                        overflow: visible;
                        tbody {
                            .el-table__row:last-child {
                                border-radius: 0 0 10px 10px;
                                border-bottom: 0;
                                td:first-child {
                                    border-radius: 0 0 0 10px;
                                }
                                td:last-child {
                                    border-radius: 0 0 10px 0;
                                }
                            }
                            .el-table__row {
                                .cell {
                                    position: relative;
                                    overflow: visible;
                                    .off-shelf {
                                        cursor: pointer;
                                        text-decoration: underline;
                                    }
                                    .off-shelf-reason {
                                        display: none;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        background: #fff;
                                        padding: 6px;
                                        border-radius: 4px;
                                        box-shadow: 0 0 5px 1px #aaa;
                                        width: 200px;
                                        text-align: left;
                                        z-index: 999;
                                    }
                                    &:hover {
                                        .off-shelf-reason {
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .pagination {
                    text-align: right;
                    margin: 1vw 0 0 0;
                    @{deep} {
                        .el-pagination {
                            .el-pager li {
                                background-color: #fff;
                                &.active {
                                    background-color: #24CEFF;
                                }
                            }
                            .btn-next,
                            .btn-prev {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #user-center{
            flex-direction: column;
            .content{
                width: 100%;
                margin-top:-40%;
                background: none;
                .topbar{
                    display: none;
                }
                .statistics{
                    width: 85.3%;
                    margin:0 auto;
                    flex-direction: column;
                    &>div{
                        width: 100%;
                        margin:0 auto 10%;
                        padding: 0;
                        box-shadow: 0px 5px 10px #D5F5FF;
                        .top{
                            align-items: center;
                            .fontFn(64,height);
                            padding-left: 8.125%;
                            padding-right: 4.6875%;
                            h2{
                                .fontFn(24);
                                color:rgba(0,0,0,0.65);
                            }
                            a{
                                .fontFn(16);
                            }
                        }
                        .user{
                            padding: 0 9.375% 13%;
                            .avatar{
                                .fontFn(60,width);
                                .fontFn(60,height);
                            }
                            &>div{
                                margin-left: 6% !important;
                                h2{
                                    .fontFn(24);
                                    font-weight: normal;
                                    color:rgba(0,0,0,0.65);
                                }
                                &>p{
                                    .fontFn(14);
                                    color:rgba(95,95,95,0.35);
                                    margin-top:0;
                                    padding-top: 4%;
                                }
                            }
                            .icon-user{
                                .fontFn(60,width);
                                .fontFn(60,height);
                            }
                        }
                        .num{
                            color:#34254C;
                            .fontFn(72);
                            margin-top:0;
                            padding-bottom: 10%;
                        }
                    }

                    .course-resources,
                    .amount{
                        .top{
                            h2{
                                .fontFn(18);
                                .icon{
                                    top:0;
                                }
                                .icon-download{
                                    .fontFn(16,width);
                                    .fontFn(20,height);
                                }
                                .icon-student{
                                    .fontFn(14,width);
                                    .fontFn(20,height);
                                }
                            }
                            button{
                                .fontFn(16);
                                color:#02A5E6;
                            }
                        }
                    }
                }

                .order-list,
                .course-list{
                    margin: 0 auto;
                    width: 94.7%;
                    &>h2{
                        padding: 0 2.8%;
                        align-items: baseline;
                        .fontFn(18);
                        color:rgba(0,0,0,0.65);
                        margin-bottom: 4%;
                        a{
                            .fontFn(14);
                            color: rgba(0, 0, 0, 0.35);
                        }
                    }
                }
                @{deep} table{
                    display: none;
                }
                @{deep} .el-table__empty-block{
                    display: none;
                }
            }
        }
        
    }
</style>